<template>
   <div>
      <com-head-banner
         :src="[require('@/assets/imgs/banner_for_duty_01.jpg')]"
      ></com-head-banner>

      <com-page-main>
         <com-new-list
            url="post?alias=duty"
            show-date
            size="medium"
            pagination
            manual_type="duty"
         ></com-new-list>
      </com-page-main>
   </div>
</template>

<script>
import ComPageMain from "@/components/PageMain";
import ComNewList from "@/components/NewList";
import ComHeadBanner from "@/components/HeadBanner";

export default {
   name: "PageParty",
   components: {
      ComNewList,
      ComHeadBanner,
      ComPageMain,
   },
};
</script>

<style lang="scss" scoped></style>
